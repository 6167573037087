export default {
  mapKeyToString(data) {
    switch (data) {
      case "NO_COMMUNICATION":
        return "No Communication";
      case "NO_SCHEDULES":
        return "No Schedules";
      case "FEED_NOT_BEING_DISPENSED":
        return "Feed Not Being Dispensed";
      case "NO_FEED_OR_FEED_STUCK":
      case "1":
        return "No Feed / Feed Stuck";
      case "MOTOR_RELAY_ISSUE":
      case "7":
        return "Motor Relay Issue";
      case "SCHEDULE_NOT_UPDATED":
        return "Schedule Not Updated";
      case "LOW_DO":
        return "Low DO";
      case "CRITICAL_DO":
        return "Critical DO";
      case "LOW_BATTERY":
        return "Low Battery";
      case "CALIBRATION_ERROR":
        return "Calibration Error";
      case "NO_DO_SENSOR_COMMUNICATION":
        return "No Communication";
      case "0":
        return "No Error";
      default:
        return "No Error";
    }
  }
};
