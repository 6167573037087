var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-card',{staticClass:"pond-alerts-table",attrs:{"shadow":"never"}},[_c('template',{slot:"header"},[_c('layout-toolbar',{staticClass:"pond__header",attrs:{"gap":"5"}},[_c('p',{staticClass:"pond-alerts-table__header card-title"},[_vm._v("\n        "+_vm._s(_vm.$t("Comn_ponds"))+" "+_vm._s(_vm.$t("Comn_alerts"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"filler"}),_vm._v(" "),_c('er-button',{class:{
          'no-padding': true,
          'action-icon': true,
          'history-button': !_vm.isHistoryAlertActive,
          'history-button-active': _vm.isHistoryAlertActive
        },attrs:{"btnType":"primary","type":"text","showIcon":false},on:{"click":_vm.handleHistory}},[_vm._v("History")]),_vm._v(" "),_c('er-button',{staticClass:"no-padding action-icon",attrs:{"btnType":"refresh","type":"text","showIcon":true},on:{"click":_vm.handelReload}}),_vm._v(" "),_c('er-dropdown',{staticClass:"highlight",attrs:{"placement":"bottom","trigger":"click","listOfItems":['Comn_download_as_excel', 'Comn_download_as_pdf']},on:{"item-click":_vm.handleTableDataDownload},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(item.value)))]}}])},[_c('template',{staticClass:"el-dropdown-link download_button",slot:"suffix"},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v("\n            file_download\n          ")])])],2),_vm._v(" "),_c('er-list-filter',{staticClass:"highlight",attrs:{"data":_vm.arrPondsForSelection,"value-key":"pond_id","search-on-key":"label","defaultCheckedKeys":_vm.getAvailablePondIds},on:{"check-change":_vm.handleChangeInSelectedPonds},scopedSlots:_vm._u([{key:"label",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.label))]}}])})],1),_vm._v(" "),_c('div',{staticClass:"divider"})],1),_vm._v(" "),_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"white"}},[(_vm.loading)?_c('Loader'):_vm._e(),_vm._v(" "),_c('er-data-tables',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale,ref:"table",attrs:{"unique-id":"pond-alerts-table__table","layout":"table","merge":{ startColumnNumber: 1, endColumnNumber: 3 },"tableData":_vm.getFilterTableData,"sortMethodForColumns":_vm.sortMethodForColumns,"columns":_vm.tableColumns,"total":_vm.total,"disable-check":false,"el-table-props":_vm.getTableProps,"action-column":false,"type":"white-header-table"},scopedSlots:_vm._u([{key:"pond",fn:function(ref){
        var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"pond","withoutHighlightStrings":row.data.pond_name}})]}},{key:"device_type",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.$tc(_vm.$constants.deviceTypes[row.data.device_type].lang_key, 1))+"\n      ")]}},{key:"device_id",fn:function(ref){
        var row = ref.row;
return [_c('span',{attrs:{"title":row.data.device_code}},[[_vm._v("\n            "+_vm._s(_vm.upm__getValue(row.data))+"\n          ")]],2)]}},{key:"status",fn:function(ref){
        var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":_vm.renderAlertName(row.data.error_code),"placement":"bottom","popperClass":"pond-alerts-table-el-tooltip__popper","effect":"light"}},[_c('img',{attrs:{"rel":"prerender","src":_vm.renderAlertIcon(row.data.error_code)}})])]}},{key:"time",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(row.data)))])]}},{key:"voltage",fn:function(ref){
        var row = ref.row;
return [_c('span',{style:(_vm.generateColorForBatterVoltage(row.data))},[_c('span',[_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(_vm.batteryVoltage(row.data)))))])])]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }