<template>
  <er-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog"
    @open="handleOpenDialog"
    :title="`${$t('Comn_update')} ${$tc('Comn_pond', 0)} ${$t('Comn_alerts')}`"
    class="history_alert_container"
    custom-class="history_alert_action_dialog"
  >
    <!-- <ValidationObserver ref="dialogListForm"> -->
      <el-form
        size="small"
        v-loading="loading"
        :model="historyAlertsObj"
        ref="historyAlertsObj"
        class="demo-historyAlertsObj"
      >
        <!-- <ValidationProvider
          rules="required"
          :name="$tc('Comn_pond', 0)"
          v-slot="{ errors }"
        > -->
          <el-form-item
            :label="$tc('Comn_pond_name', 0)"
            prop="pond"
          >
          <!-- :error="errors[0]" -->
            <er-select
              v-model="historyAlertsObj.pond"
              @change="handleChangePond($event, 'pondId')"
              :placeholder="$tc('Comn_pond_name', 0)"
              collapse-tags
              value-key="title"
              :showSelectAll="true"
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
            >
              <template v-for="pond in pondsData">
                <el-option
                  :key="pond.pond_id"
                  :label="pond.pond_name"
                  :value="pond.pond_id"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        <!-- </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="$t('Comn_pond_devices_type')"
          v-slot="{ errors }"
        > -->
          <el-form-item
            :label="$t('Comn_pond_devices_type')"
            prop="pond"
          >
          <!-- :error="errors[0]" -->
            <er-select
              v-model="historyAlertsObj.device_type"
              @change="handleChangePond($event, 'device_type')"
              :placeholder="$t('Comn_pond_devices_type')"
              collapse-tags
              value-key="title"
              :showSelectAll="true"
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
            >
              <template v-for="pond in deviceTypeData">
                <el-option
                  :key="pond.device_type"
                  :label="deviceTypes[pond.device_type]"
                  :value="pond.device_type"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        <!-- </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="$t('Comn_Device_ID')"
          v-slot="{ errors }"
        > -->
          <el-form-item
            :label="$t('Comn_Device_ID')"
            prop="device_id"
          >
            <!-- :error="errors[0]" -->
            <er-select
              v-model="historyAlertsObj.device_id"
              @change="handleChangePond($event, 'device_id')"
              :placeholder="$t('Comn_Device_ID')"
              collapse-tags
              value-key="title"
              :showSelectAll="true"
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
            >
              <template v-for="pond in devicesData">
                <el-option-group
                  :key="pond.group_label"
                  :label="deviceTypes[pond.group_label]"
                >
                  <el-option
                    v-for="eachDevice in pond.options"
                    :key="eachDevice.device_id"
                    :label="eachDevice.device_code || eachDevice.device_title"
                    :value="eachDevice.device_id"
                  ></el-option>
                </el-option-group>
              </template>
            </er-select>
          </el-form-item>
        <!-- </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="`${$t('Comn_alert')} ${$t('Comn_type')}`"
          v-slot="{ errors }"
        > -->
          <el-form-item
            :label="`${$t('Comn_alert')} ${$t('Comn_type')}`"
            prop="pond"
          >
            <!-- :error="errors[0]"
              @change="handleChangePond($event, 'device_type')" -->
            <er-select
              v-model="historyAlertsObj.alert_type"
              :placeholder="`${$t('Comn_alert')} ${$t('Comn_type')}`"
              collapse-tags
              value-key="title"
              :showSelectAll="true"
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
            >
              <template v-for="pond in alertsData">
                <el-option
                  :key="pond.error_id"
                  :label="renderAlertName(pond.error_code)"
                  :value="pond.error_code"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        <!-- </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="`${$t('Comn_fixed_by')}`"
          v-slot="{ errors }"
        > -->
          <el-form-item
            :label="`${$t('Comn_fixed_by')}`"
            prop="pond"
          >
            <!-- :error="errors[0]" -->
            <er-select
              size="small"
              v-model="historyAlertsObj.fixed_by"
              :clearable="true"
              value-key="code"
              autocomplete="new-country"
              filterable
            >
              <er-option
                v-for="(user, index) in subUsersData"
                :key="index"
                :label="ftm__capitalize(user.name)"
                :value="user.value"
                :disabled="user.disabled"
              ></er-option>
            </er-select>
          </el-form-item>
        <!-- </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="`${$t('Comn_fixed_date')}`"
          v-slot="{ errors }"
        > -->
          <el-form-item
            :label="`${$t('Comn_fixed_date')}`"
            prop="pond"
          >
            <!-- :error="errors[0]" -->
            <el-date-picker
            size="small"
            :clearable="true"
            v-model="historyAlertsObj.fixed_date"
            type="datetime"
            format="dd-MMM-yy HH:mm"
            value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
            :picker-options="fromDatePickerOptions"
          />
          </el-form-item>
        <!-- </ValidationProvider> -->
        <el-form-item
            :label="`${$t('Comn_reason')}`"
            prop="reason"
          >
            <el-input
              v-model="historyAlertsObj.reason"
              size="small"
              type="textarea"
              maxlength="250"
              :autosize="{ minRows: 1, maxRows: 2 }"
              :rows="2"
            ></el-input>
          </el-form-item>
      </el-form>
    <!-- </ValidationObserver> -->
    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        @click="submitResourceDetails"
        :loading="loading"
        :disabled="isReadOnly || disable_field"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from '@/mixins/errorHandlerMixin';
import errorKeyMapMixin from '@/mixins/errorKeyMapMixin';
import { mapGetters, mapActions } from 'vuex';
// import { extend } from 'vee-validate';
import userPreferenceMixin from '@/mixins/userPreferenceMixin';
import { pondMotherSettings as cnstsPMSettings } from '@/constants/settings';
import filtersMixin from '@/mixins/filtersMixin.js';
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import moment from "moment";
import errorAlertStringMapping from '@/utils/errorAlertStringMapping'
// import {
//   required_if,
//   required,
//   max_value,
//   min_value
// } from 'vee-validate/dist/rules';
// extend("required", {
//   ...required,
//   message: "{_field_} is required",
// });

export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    userPreferenceMixin,
    filtersMixin,
    datesHandlerMixin
  ],
  props: {
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ''
    },
    tabData: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      disable_field: false,
      loading: false,
      errorhighlight: false,
      checkmode: false,
      feedermode: false,
      checkdispensefeed: false,
      cnstsPMSettings,
      historyAlertsObj: {
        pond: [],
        device_type: [],
        device_id: [],
        alert_type: [],
        fixed_by: '',
        fixed_date: '',
        reason: '',
      },
      pmsData: [],
      modeData: {},
      selectedData: [],
      pondsOptionsData: [],
      fromDatePickerOptions: {
        disabledDate: this.disabledDate,
      },
      deviceOptionsData: [],
      deviceTypeOptionsData: [],
      alertTypeOptionsData: [],
      deviceTypes: {
        POND_GUARD: "PondGuard",
        FEED_BLOWER: "FeedBlower",
        SHRIMP_SNAP: "ShrimpSnap",
        POND_MOTHER: "PondMother",
        SHRIMP_TALK: "ShrimpTalk",
        GATEWAY: "Gateway",
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits",
      getUserTimeZoneString: "getUserTimeZoneString",
      getCurrUserLocation: "getCurrUserLocation",
      getUserProfile: "getUserProfile"
    }),
    ...mapGetters("alertsInfo", {
      getSubUsersData: "getSubUsersData",
      getAlertsHistoryUsersData: "getAlertsHistoryUsersData",
      getFilterTableHistoryData: "getFilterTableHistoryData",
    }),
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog('close');
      }
    },
    pondsData() {
      return this.getFilterTableHistoryData.reduce((acc, curr) => {
        if (!acc.find((item) => item.pond_id === curr.pond_id)) {
          console.log("curr", curr.pond_id === undefined, curr.pond_name === undefined);
          if (curr.pond_id === undefined && curr.pond_name === undefined) {
            acc.push({ pond_id: undefined, pond_name: 'No Pond' });
          } else {
            acc.push({ pond_id: curr.pond_id, pond_name: curr.pond_name });
          }
        }
        return acc;
      }, []);
    },
    deviceTypeData() {
      return this.deviceTypeOptionsData || [];
    },
    devicesData() {
      return this.historyAlertsObj.device_type.reduce((acc, curr) => {
          acc.push({
          group_label: curr,
          group_key: curr,
          options: this.deviceOptionsData.filter(ele => ele.device_type === curr)
        })
        return acc;
      }, []) || [];
    },
    alertsData() {
      return this.alertTypeOptionsData || [];
    },
    subUsersData() {
      const userType = JSON.parse(localStorage.getItem("curr-user-type"));
      if (userType === "POND_WORKER" || userType === "POND_TECHNICIAN") {
        const subUserData = this.getAlertsHistoryUsersData.reduce(
          (acc, curr) => {
            const user = {
              name: curr.full_name,
              value: curr._id,
              disabled:
                JSON.parse(localStorage.getItem(`${userType}-id`)) !== curr._id
            };
            acc.push(user);
            return acc;
          },
          []
        );
        return subUserData.sort((user1, user2) => {
          return this.$commonUtils.alphaNumericComparator(
            user1.name,
            user2.name
          );
        });
      } else {
        const profileData = this.getUserProfile;
        var subUsersDataCopy = this.$lodash.cloneDeep(this.getSubUsersData);
        var subUserIds = [];
        subUsersDataCopy.forEach((user) => {
          subUserIds.push(user.value);
        });
        if (!subUserIds.includes(profileData._id)) {
          subUsersDataCopy.push({
            name: profileData.full_name,
            value: profileData._id,
            disabled: false
          });
        }
        return subUsersDataCopy.sort((user1, user2) => {
          return this.$commonUtils.alphaNumericComparator(
            user1.name,
            user2.name
          );
        });
      }
    },
  },
  methods: {
    ...mapActions("alertsInfo", {
      patchAlertsHistoryData: "patchAlertsHistoryData"
    }),
    renderAlertName(alertCode) {
      return errorAlertStringMapping.mapKeyToString(alertCode);
      // console.log(error);
    },
    handleCloseDialog(event) {
      this.historyAlertsObj = {
        pond: [],
        device_type: [],
        device_id: [],
        alert_type: [],
        fixed_by: '',
        fixed_date: '',
        reason: '',
      };
      this.deviceOptionsData = [];
      this.deviceTypeOptionsData = [];
      this.alertTypeOptionsData = [];
      // this.$refs.dialogListForm.reset();
      this.$emit('close_dialog', event);
    },
    handleOpenDialog() {
      this.historyAlertsObj = {
        pond: [],
        device_type: [],
        device_id: [],
        alert_type: [],
        fixed_by: '',
        fixed_date: '',
        reason: '',
      };
      this.deviceOptionsData = [];
      this.deviceTypeOptionsData = [];
      this.alertTypeOptionsData = [];
      // this.$nextTick(() => {
      //   this.$refs.dialogListForm.reset();
      // });
    },
    async handleChangePond(id, type) {
      if (type === 'pondId') {
        this.deviceTypeOptionsData = [];
        this.deviceOptionsData = [];
        this.alertTypeOptionsData = [];
        this.historyAlertsObj.device_type = [];
        this.historyAlertsObj.device_id = [];
        this.historyAlertsObj.alert_type = [];
      }
      if (type === 'device_type') {
        this.deviceOptionsData = [];
        this.alertTypeOptionsData = [];
        this.historyAlertsObj.device_id = [];
        this.historyAlertsObj.alert_type = [];
      }
      if (type === 'device_id') {
        this.alertTypeOptionsData = [];
        this.historyAlertsObj.alert_type = [];
      }
      await this.getFilterTableHistoryData.reduce((acc, curr) => {
        if (type === 'pondId' && this.historyAlertsObj.pond.includes(curr.pond_id) && !this.deviceTypeOptionsData?.find((item) => item.device_type === curr.device_type)) {
          this.deviceTypeOptionsData.push(curr);
          acc.push(curr);
        }
        if (type === 'device_type' && this.historyAlertsObj.pond.includes(curr.pond_id) && !this.deviceOptionsData?.find((item) => item.device_id === curr.device_id) && this.historyAlertsObj.device_type.includes(curr.device_type)) {
          console.log("curr", curr);
          this.deviceOptionsData.push(curr);
        }
        if (type === 'device_id' && this.historyAlertsObj.pond.includes(curr.pond_id) && !this.alertTypeOptionsData?.find((item) => item.error_code === curr.error_code) && this.historyAlertsObj.device_id.includes(curr.device_id)) {
          this.alertTypeOptionsData.push(curr);
        }
        return acc;
      }, []);
    },
    formatDateMethodTableLevel(UtcDate) {
      return (
        UtcDate &&
        this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          UtcDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          this.getUserTimeZoneString,
          this.getLocaleObj
        )
      );
    },
    async submitResourceDetails() {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        if (this.historyAlertsObj.pond.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_update_error_msg") + this.$t("Comn_pond_name")
              }
            ]
          };
        } else if (this.historyAlertsObj.device_type.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_update_error_msg") + this.$t("Comn_pond_devices_type")
              }
            ]
          };
        } else if (this.historyAlertsObj.device_id.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_update_error_msg") + this.$t("Comn_Device_ID")
              }
            ]
          };
        } else if (this.historyAlertsObj.alert_type.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_update_error_msg") + this.$t("Comn_alert") + " " + this.$t("Comn_device_type")
              }
            ]
          };
        } else if (!this.historyAlertsObj.fixed_by) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_update_error_msg") + this.$t("Comn_fixed_by")
              }
            ]
          };
        } else if (!this.historyAlertsObj.fixed_date) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_update_error_msg") + this.$t("Comn_fixed_date")
              }
            ]
          };
        } else {
          try {
            const payloadData = this.getFilterTableHistoryData.reduce((acc, curr) => {
            if (this.historyAlertsObj.pond.includes(curr.pond_id) && this.historyAlertsObj.device_type.includes(curr.device_type) && this.historyAlertsObj.device_id.includes(curr.device_id) && this.historyAlertsObj.alert_type.includes(curr.error_code)) {
                acc.push(curr);
              }
              return acc;
            }, [])
            var finalPayload = payloadData.reduce((acc, curr) => {
              const obj = {
                _id: curr.error_id,
                resolved_by: this.historyAlertsObj.fixed_by,
                resolved_date: this.dhm__castUserUTCToUTCDateObj(
                          this.historyAlertsObj.fixed_date
                        ),
                resolved_notes: this.historyAlertsObj.reason,
                device_type: curr.device_type
              }
              acc.push(obj);
              return acc;
            }, [])
            const response = await this.patchAlertsHistoryData({
              payload: finalPayload
            });
            finalPayload = [];
            if (Object.keys(response).length > 0) {
              this.$emit('close_dialog', false, 'save');
              this.loading = false;
              this.$notify({
                title: this.$t("Usrs_success_msg"),
                message: this.$t("alert_update_success"),
                duration: 5000,
                type: "success"
              });
            }
          } catch (err) {
            this.ehm__errorMessages(err, true);
          }
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    disabledDate(date) {
      return moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z" > moment(new Date()).format("YYYY-MM-DD") + "T23:59:59.999Z";
    },
  }
}
</script>

<style lang="scss">
.history_alert_container {
  .toolbar-layout {
    justify-content: flex-end !important;
  }
  .el-dialog {
    @include responsiveProperty(width, 35%, 35%, 35%);
  }
  .el-dialog__body {
    padding: 15px 15px;
    height: auto;
  }

  .el-dialog .el-dialog__body {
    @include responsiveProperty(min-height, 220px, 220px, 250px);
  }
  .select-units,
  .select-resource-type {
    width: 100%;
  }
  // .el-input-number {
  //   width: 100%;
  // }

  .el-select .el-tag.el-tag--info .el-tag__close {
    display: inline-block !important;
    pointer-events: all !important;
  }

  .el-input--mini {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    // width: 275px;
  }
  .el-textarea {
    width: 220px
  }
}
.history_alert_action_dialog {
  border-radius: 10px;
  .errorwithouthighlight {
    display: flex;
    word-break: break-word !important;
    // align-items: center;
    justify-content: center;
    @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
    .material-icons-outlined {
      margin-top: -1px !important;
      margin-right: 4px;
      word-break: break-word !important;
    }
  }
  .errorCss {
    color: #f56c6c;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
  }

  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 220px, 220px, 220px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 177px, 215px, 265px);
    line-height: 20px;
    display: contents;
  }
  .el-form-item__error {
    top: auto;
    @include responsiveProperty(left, 193px, 230px, 281px);
  }
  .el-form-item--small .el-form-item__error {
    @include responsiveProperty(margin-top, 31px, 31px, 41px);
  }
}
</style>
